import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./articles.css";
/**
 *
 */
const AIandCulture2 = () => {
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	// Keep all left and right section refs in arrays
	const lSections = useRef([]);
	const rSections = useRef([]);

	// Methods to create refs when called upon DOM elements
	const pushLRef = (el) => lSections.current.push(el);
	const pushRRef = (el) => rSections.current.push(el);

	// Tracks video visibility. Set upon scroll position
	const [isVideo1Visible, setShowVideo1] = useState(true);
	const [isVideo2Visible, setShowVideo2] = useState(false);
	const [isVideo3Visible, setShowVideo3] = useState(false);
	// const [isVideo4Visible, setShowVideo4] = useState(false);
	// const [isVideo5Visible, setShowVideo5] = useState(false);

	useEffect(() => {
		let tl = gsap.timeline();
		let mm = gsap.matchMedia();
		const sectionsLength = lSections.current.length;

		for (let i = 0; i < sectionsLength; i++) {
			mm.add("(min-width: 992px)", () => {
				let startVal = "clamp(top top)";
				let endVal = "clamp(bottom center)";
				if (i < 2) {
					startVal = "clamp(top 64px)";
					endVal = "clamp(bottom bottom-400px)";
				}
				tl.to(rSections.current[i], {
					scrollTrigger: {
						pin: lSections.current[i],
						trigger: rSections.current[i],
						start: startVal,
						end: endVal,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						markers: false,
						// Tracks scroll progression (progress is a value of 0-1)
						// i value represents which left section is currently in view
						onUpdate: ({ progress }) => {
							// console.log("progress: ", progress.toFixed(2));
							if (i === 0) {
								/*
								if (progress >= 0.8) {
									//show video 3
									// console.log("show video 3");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(true);
								} else if (progress >= 0.57) {
									//show video 2
									// console.log("show video 2");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(true);
									setShowVideo5(false);
								} else*/ if (progress >= 0.5) {
									//show video 1
									// console.log("show video 1");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(true);
									// setShowVideo4(false);
									// setShowVideo5(false);
								} else if (progress >= 0.3) {
									//show image 2
									// console.log("show image 2");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(true);
									setShowVideo3(false);
									// setShowVideo4(false);
									// setShowVideo5(false);
								} else {
									//show image 1
									// console.log("show image 1");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(true);
									setShowVideo2(false);
									setShowVideo3(false);
									// setShowVideo4(false);
									// setShowVideo5(false);
								}
							}
						},
					},
				});
			});
		}

		ScrollTrigger.sort();
	}, [lSections]);

	return (
		<div className="main-wrapper">
			<MainMenu isArticle={true} />

			{/* BEGIN YOUR CUSTOM MARKUP HERE */}

			<div className="hero light-mode">
				<div className="left ai-culture curtain">
					<h1>
						<div className="eyebrow">Experiment 05:</div>
						Is AI diluting our culture in its pursuit of perfection?
					</h1>
					<div className="info">
						<div className="breadcrumb">Culture + AI</div>
						<div className="tagging">
							<ul>
								<li>#Cultural</li>
								<li>#Visual</li>
								<li>#People</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="right">
					<section>
						<p>
							Imagine a very talented artist standing before a blank canvas,
							trying to capture the essence of a bustling market in Dubai or the
							spirit of a celebration in Lagos. Could they create the scene
							having never been to the region? Maybe. Could they capture the
							nuance to the point where you feel like you're there? Probably.
							Could the texture and storytelling pass the scrutiny of a local
							culture critic? Probably not.
						</p>
						<p>
							Herein lies the problem <i>and</i> the opportunity of AI. It's a
							rocket booster of creativity in many ways, but correctness is not
							one of them – just think of all those extra fingers we’ve become
							accustomed to in AI generated images. And, if AI can’t understand
							that humans (usually) have only 10 fingers, how could it ever
							understand the nuances of culture?
						</p>
						<p>
							AI often overlooks the subtle, yet significant, details that give
							life and respect to the subjects it depicts. It’s not just about
							having enough information but rather understanding the vast,
							interconnected implications of every choice.
						</p>
						<p>
							The computational challenge here is staggering. To get AI to truly
							understand and replicate these nuances, it would require
							processing a near-infinite number of cultural cues, historical
							contexts, and social sensitivities. We’re talking about a scale of
							data and computing power that boggles the mind — something like
							billions of hours of computational processing for a single
							culturally authentic image, if we ever get there.
						</p>
					</section>
				</div>
			</div>
			<div className="scrollingsections">
				<div className="scroll-element top-aligned">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<img
									id="image1"
									className={`${isVideo1Visible ? "" : "hide"}`}
									src="../images/ai-culture/dove.jpg"
									alt="Where Beauty meets AI"
								/>
								<img
									id="image2"
									className={`${isVideo2Visible ? "" : "hide"}`}
									src="../images/ai-culture/image1.jpg"
									alt="AI rendering"
								/>
								<img
									id="image3"
									className={`${
										isVideo3Visible ? "narrower" : "hide narrower"
									}`}
									src="../images/ai-culture/image2.jpg"
									alt="AI rendering"
								/>
								{/* <video
									id="video1"
									controls
									className={`${isVideo3Visible ? "" : "hide"}`}
									poster="../images/naming/video1.png"
									src="../images/lilah/Prompt_redacted_v2.mp4"
								></video>

								<video
									id="video2"
									controls
									className={`${isVideo4Visible ? "" : "hide"}`}
									poster="../images/naming/video2.png"
									src="../images/lilah/Lilah_combo.mp4"
								></video>

								<video
									id="video3"
									controls
									className={`${isVideo5Visible ? "" : "hide"}`}
									poster="../images/naming/video3.png"
									src="../images/lilah/New app.mp4"
								></video> */}
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<p>
								As AI keeps growing and learning it has taken on an almost
								omnipresent persona for those who use it, and for those who fear
								using it. We see AI as becoming a part of everything we touch,
								see, and feel, and with the lurid potential to take over the
								roles and responsibilities we have come to rely on.
							</p>
							<p>
								AI, to its credit, is powerful. It has the ability to create
								images that make us question if what we are seeing is real or
								fake – built by the analysis of millions upon millions of pieces
								of data and content. But the question is, is this a pro or a
								con? Sure, more information provides AI with more context to
								learn from so it can fine tune its models and applications, but
								the more we add, the more muddled that content becomes.
							</p>
							<p>
								So, let’s think about that artist again. They’re standing before
								that blank canvas, still never having been to Dubai or Lagos,
								but they have an entire pile of reference photos in front of
								them. As they sort through that pile of photos, they pick up
								bits of inspiration – a building here, a sign there – and they
								stitch together this painting piece by piece. But what they
								didn’t know is some of those reference photos were old, others
								mislabeled – so they didn’t quite capture that bustling market
								in Dubai, instead they captured a bustling market with elements
								of Dubai, elements of Abu Dhabi, and even a bit of Rio.
							</p>

							<p>AI is very much the same as this painter.</p>

							<p className="section-subhead">
								If we give it too little information it will be unable to return
								anything meaningful, and if we give it too much information it
								risks muddling the context to something unrecognizable.
							</p>
							<section className="video">
								<img
									src="../images/ai-culture/dove.jpg"
									alt="Where Beauty meets AI"
								/>
							</section>
							<p>
								AI image generators have a reputation, rightfully so, for
								cultural bias in their models that lead to underrepresentation
								of certain cultures and races and sometimes offensive imagery.
								While users of AI have begun to adapt to this knowledge, there
								is even more to consider when it comes to representing the
								cultural nuances of the people and places we represent.
							</p>
							<p>
								Companies like Dove have taken a great approach in creating more
								realistic people. However, even with the most detailed and
								articulate prompt, AI still often fails to go beyond the surface
								level.
							</p>
							<p className="section-subhead">
								This concern isn’t purely hypothetical – we’ve seen it in
								action.
							</p>
							<p>
								Lippincott is a global company, and that means we frequently
								work across countries and cultures and seek to represent those
								cultures with authenticity. When working an Emirati company
								recently, we learned exactly how broad AI’s brush strokes are.
							</p>

							<p>
								For example, if we try to create an accurate Emirati man using
								AI, we can see the cultural nuances that it fails to pick up on.
							</p>
							<section className="video">
								<img src="../images/ai-culture/image1.jpg" alt="AI rendering" />
							</section>
							<ul className="list-style">
								<li>
									You can clearly notice that the real image there is a
									Tarbousha (1) that hangs from the Kandura (2). Whereas, AI
									fails to pick up on this very important detail.
								</li>
								<li>
									On the Kandura (2) you can see that the buttons are visible,
									when in the other image they are not.
								</li>

								<li>
									AI also has a habit of adding in false details (3) that are
									not accurate to culturally-specific attire.
								</li>
							</ul>

							<section className="video">
								<img src="../images/ai-culture/image2.jpg" alt="AI rendering" />
							</section>
							<p className="section-subhead">
								This is one example that highlights how easy it is for AI to
								create culturally inaccurate people, opening the door for
								causing offense or coming across as culturally insensitive.
							</p>

							<p>
								Even if we give AI the canvas and place it right in the middle
								of the subject it is going to miss some details. Because AI
								isn’t just looking at the specific thing we want it to paint –
								it is pulling in details from the millions of other markets and
								celebrations it has seen – those which is sees as similar, but
								in reality are very, very different.
							</p>
							<p>
								So, if AI can’t get it right on its own, can we take a new
								approach to help it get there?
							</p>
							<p>
								Can we use tailored data models to engineer culturally accurate
								images? Can we teach AI to question its own creations to
								continuously refine them? Or will we always need that human
								touch to assess, to point out the mistakes, and to make them
								right?
							</p>
							<p>
								It is clear that, on its own, AI cannot achieve the specificity
								that we require and its subjects deserve. This is where we need
								go beyond just being gatekeepers of AI, we need to be active
								collaborators.
							</p>
							<p>
								AI won’t take your job but the person who knows how to use it
								will. And that is us. The people who will stand over AI’s
								shoulder, tell it not to muddle the images – but instead to
								review them one by one, to question their authenticity, and to
								build with care so we can truly capture the texture of the scene
								and essence of the culture.
							</p>

							{/* <section className="video">
								<video
									controls
									poster="../images/naming/video1.png"
									src="../images/lilah/Prompt_redacted_v2.mp4"
								></video>
							</section> */}
						</div>
					</div>
				</div>
			</div>
			{/* END YOUR CUSTOM MARKUP HERE */}

			<Footer />
		</div>
	);
};

export default AIandCulture2;
